export default class IdentityCard
{
    constructor(type, cardId, divId, isNifRequired, onChange, addError, inputId)
    {
		this.divId = divId;
        this.type = type;
        this.cardId = this.normalize(cardId);
        this.isNifRequired = isNifRequired;
        this.onChange = onChange;
        this.addError = addError;
        this.inputId = inputId != null ? inputId : "dni";
    }
    
    paintIdentityCardHTML()
    {
		let identityCardsDiv = document.getElementById(this.divId);
		
		if(identityCardsDiv == null)
		{
			return;	
		}
		
		let html = 	'<div class="large-12 medium-12 columns">'
				+ '<label for="identityCard">' + polyglot.t("global.Identity_card") + '</label>'
				+ '<select id="identityCardType" style="float:left; width:40%" ' + (this.onChange != null ? 'onchange="' + this.onChange + '" ' : '') + '></select>'
				+ '<input class="input" name="' + this.inputId + '" id="' + this.inputId + '" type="text" style="float:right; width:60%" placeholder="' + polyglot.t("registro.dni") 
				+ '" ' + (this.onChange != null ? 'onchange="' + this.onChange + '" ' : '') + ' value="' + (this.cardId != null ? this.cardId : "") + (this.isNifRequired ? '" required >' : '" >')
				+ (this.addError != null && this.addError ? '<small class="error">' + polyglot.t("registro.Idni") + '</small>' : '');
			+ '</div>'
		
		identityCardsDiv.innerHTML = html;
		this.fillIdentityCardOptions();
		
		if(this.type != null) document.querySelectorAll("#" + this.divId + " select")[0].value = this.type;;
	}
	
	fillIdentityCardOptions()
	{
		if(document.getElementById('identityCardType') == null)
		{
			return;	
		}
		
		for(let i = 0; i < IdCardTypes.length; i++)
		{
			let currCardType = IdCardTypes[i];
			
			if(IdCardTypes[i].litCardType == 'Not defined')
			{
				continue;
			}
			
			let option = document.createElement("option");
			option.value = currCardType.idCardType;
			
			if(!currCardType.countryCode)
			{
				option.innerText = polyglot.t("global.CardTypes_" + currCardType.litCardType);
				
			}
			
			else 
			{
				option.innerText = polyglot.t(currCardType.litCardType);
			}
			
			document.querySelector("#" + this.divId + " #identityCardType").appendChild(option);
		}
	}
	
	checkIdentityCardData()
	{
		this.cardId = document.querySelector("#"+ this.divId + " #" + this.inputId) != null ? document.querySelector("#"+ this.divId + " #" + this.inputId).value : null;
		this.type = document.querySelector("#" + this.divId + " #identityCardType") != null ? document.querySelector("#" + this.divId + " #identityCardType").value : null;
	
		if(this.cardId != null && this.cardId.length > 0 && isValidPersonalNumberIdentifier(this.type, this.cardId) == IdCardValidationResultStatus.NOT_VALID)
		{
			alertaKO(polyglot.t("ERROR_CODE.ERR_TI_TICKET_DATA_ID_CARD_INVALID"));
			return false; 
		}
		
		return true;
	}
	
	getIdentityCardData(compradorGuardar)
	{
		if(!this.checkIdentityCardData())
		{
			return null;	
		}
		
		compradorGuardar.nif = this.cardId;
		compradorGuardar.idCardType = this.type;
		
		return compradorGuardar;
	}
	
	fillGenericIdentityCardFields(cardId, cardTypeId)
	{
		this.cardId = cardId;
		this.type = cardTypeId;
		if(document.querySelector('#' + this.divId + ' #' + this.inputId) != null && this.cardId != null) document.querySelector('#' + this.divId + ' #' + this.inputId).value = this.cardId;
		if(document.querySelector('#' + this.divId + ' #identityCardType') != null && this.type != null) document.querySelector('#' + this.divId + ' #identityCardType').value = this.type;
	}

    normalize(cardId)
    {
        // Here we can write generic code to normalize the identity document cardId
        // for example, removing white spaces or hyphens
    }

    validate()
    {
        // empty
    }

    static create(type, cardId)
    {
        // Create an identity document object of the specified type
        if(type == IdCardType.RUT_CL) 
        {
            return new ChileanRut(cardId);
        }
        else if(type == IdCardType.DNI_ES) 
        {
            return new SpanishDni(cardId);
        }
        else if(type == IdCardType.CPF_BR) 
        {
            return new BrazilianCpf(cardId);
        }
        else if(type == IdCardType.CNPJ_BR) 
        {
            return new BrazilianCnpj(cardId);
        }
        else if(type == IdCardType.PASSPORT)
        {
            return new Passport(cardId);
        }
        else if(type == IdCardType.NIE_ES) 
        {
            return new SpanishNie(cardId);
        }
        else if(type == IdCardType.CIF_ES) 
        {
            return new SpanishCif(cardId);
        }
        else if(type == IdCardType.DNI_PE) 
        {
            return new PeruvianDni(cardId);
        }
        else if(type == IdCardType.PESEL_PL) 
        {
            return new PolishPesel(cardId);
        }
        else if(type == IdCardType.RNC_DO)
        {
            return new DominicanRnc(cardId);
        }
        else if(type == IdCardType.CEDULA_DO)
        {
            return new DominicanCedula(cardId);
        }
        else if(type == IdCardType.NIT_CO)
        {
            return new ColombianNit(cardId);
        }
        else if(type == IdCardType.CEDULACIUDADANIA_CO)
        {
            return new ColombianCedula(cardId);
        }
        else if(type == IdCardType.TARJETAIDENTIDAD_CO)
        {
            return new ColombianTarjeta(cardId);
        }
        else if(type == IdCardType.CEDULAEXTRANJERIA_CO)
        {
            return new ColombianCedulaExtranjeria(cardId);
        }
        else 
        {
            return new NotDefinedCard(cardId);
        }
    }
}

class NotDefinedCard extends IdentityCard
{
    constructor(cardId)
    {
        super('Not defined card', cardId);
    }
    
    validate() 
    {
        return true;
    }
}
  
class SpanishDni extends IdentityCard
{
    constructor(cardId)
    {
        super('Spanish DNI', cardId);
    }
    
    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }
    
    validate() 
    {
        // We override the validate method to check that the Spanish DNI cardId is correct
        const regex_dni = /^\d{8}[A-Z]$/;
        const regex_nif = /^[XYZ][0-9]{7}[A-Z]$/;
        
        let dni = this.cardId;
        let numero = null;
        
        if(regex_dni.test(this.cardId))
        {
            numero = dni.substr(0,dni.length-1);
        }
        else if(regex_nif.test(this.cardId)) 
        {
            const primerDigito = dni.substr(0,1);
            const primeraSuma = (primerDigito == "X") ? "0" : (primerDigito == "Y") ? "1" : "2";
            numero = primeraSuma + dni.substr(1,dni.length-2);
        }
        else
        {
            return false;	
        }
        
        const letr = dni.substr(dni.length-1,1);
        numero = numero % 23;
        let letra='TRWAGMYFPDXBNJZSQVHLCKET';
        letra=letra.substring(numero,numero+1);
        return letra == letr.toUpperCase();
    }
}

class SpanishCif extends IdentityCard 
{
    constructor(cardId) 
    {
        super('Spanish CIF', cardId);
    }
    
    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }
    
    validate() 
    {

        if (!this.cardId || this.cardId.length !== 9)
        {
            return false;
        }
    
        const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
        const digits = this.cardId.substr(1, this.cardId.length - 2);
        const letter = this.cardId.substr(0, 1);
        const control = this.cardId.substr(this.cardId.length - 1);
        let sum = 0;
        let digit;
    
        if (!letter.match(/[A-Z]/))
        {
            return false;
        }
    
        for (let i = 0; i < digits.length; ++i)
        {
            digit = parseInt(digits[i]);
    
            if (isNaN(digit))
            {
                return false;
            }
    
            if (i % 2 === 0)
            {
                digit *= 2;

                if (digit > 9)
                {
                    digit = parseInt(digit / 10) + (digit % 10);
                }
    
                sum += digit;
            }
            else
            {
                sum += digit;
            }
        }
    
        sum %= 10;

        if (sum !== 0)
        {
            digit = 10 - sum;
        }
        else
        {
            digit = sum;
        }
    
        if (letter.match(/[ABEH]/))
        {
            return String(digit) === control;
        }

        if (letter.match(/[NPQRSW]/))
        {
            return letters[digit] === control;
        }
    
        return String(digit) === control || letters[digit] === control;
    }
}

class SpanishNie extends IdentityCard 
{

    constructor(cardId) 
    {
        super('Spanish NIE', cardId);
    }
    
    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }
    
    validate() 
    {
        const nieRegex = /^[XYZ]\d{7}[a-zA-Z]$/;

        if (!nieRegex.test(this.cardId))
        {
            return false;
        }
    
        const controlLetters = this.cardId.charAt(8).toUpperCase();
        const firstChar = this.cardId.charAt(0);
    
        let NieCardId = this.cardId.substring(1, 8);
    
        switch (firstChar) 
        {
            case 'X':
                NieCardId = '0' + NieCardId;
                break;
            case 'Y':
                NieCardId = '1' + NieCardId;
                break;
            case 'Z':
                NieCardId = '2' + NieCardId;
                break;
            default:
                return false;
        }
    
        return new SpanishDni(NieCardId + controlLetters).validate();
    }
}

class Passport extends IdentityCard 
{
    constructor(cardId) 
    {
        super('Passport', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the passport cardId is correct
        const regex = /^[A-Z0-9]{8,9}$/;
        return regex.test(this.cardId);
    }
}

class ChileanRut extends IdentityCard 
{
    constructor(cardId) 
    {
        super('RUT', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces, hyphens and dots, and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Chilean RUT cardId is correct
        const regex = /^\d{7,8}[0-9K]$/;

        const cardId = this.cardId.replace(/-|\./g, '');

        if (!regex.test(cardId)) 
        {
            return false;
        }

        const rut = cardId.slice(0, -1);
        const dv = cardId.slice(-1);
        return this.calculateDv(rut) === dv;
    }

    calculateDv(rut) 
    {
        // This function calculates the verification digit (DV) for a Chilean RUT
        let sum = 0;
        let mul = 2;

        for (let i = rut.length - 1; i >= 0; i--)
        {
            sum += rut.charAt(i) * mul;
            mul = mul % 7 === 0 ? 2 : mul + 1;
        }

        const dv = 11 - sum % 11;
        return dv === 11 ? '0' : dv === 10 ? 'K' : String(dv);
    }
}

class BrazilianCpf extends IdentityCard 
{
    constructor(cardId) 
    {
        super('CPF', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces, hyphens and dots, and convert letters to uppercase
        return cardId.trim().replace(/-|\./g, '').toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Brazilian CPF cardId is correct
        const regex = /^\d{11}$/;

        if (!regex.test(this.cardId)) 
        {
            return false;
        }

        const cpf = this.cardId;
        const firstDv = this.calculateDv(cpf.slice(0, 9));
        const secondDv = this.calculateDv(cpf.slice(0, 9) + firstDv);
        return firstDv === cpf.slice(9, 10) && secondDv === cpf.slice(10, 11);
    }

    calculateDv(cardId) 
    {
        // This function calculates the verification digit (DV) for a Brazilian CPF
        let sum = 0;

        for (let i = 0; i < cardId.length - 1; i++) 
        {
            sum += parseInt(cardId.charAt(i), 10) * (cardId.length - i);
        }

        const dv = 11 - sum % 11;
        return dv > 9 ? '0' : String(dv);
    }
}

class BrazilianCnpj extends IdentityCard 
{
    constructor(cardId) 
    {
        super('CNPJ', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces, hyphens and dots, and convert letters to uppercase
        return cardId.trim().replace(/-|\./g, '').toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Brazilian CNPJ cardId is correct
        const regex = /^\d{14}$/;

        if (!regex.test(this.cardId)) 
        {
            return false;
        }

        const cnpj = this.cardId;
        const firstDv = this.calculateDv(cnpj.slice(0, 12));
        const secondDv = this.calculateDv(cnpj.slice(0, 12) + firstDv);
        
        return firstDv === cnpj.slice(12, 13) && secondDv === cnpj.slice(13, 14);
    }

    calculateDv(cardId) 
    {
        // This function calculates the verification digit (DV) for a Brazilian CNPJ
        let sum = 0;
        let weight = 2;
        
        for (let i = cardId.length - 1; i >= 0; i--) 
        {
            sum += parseInt(cardId.charAt(i), 10) * weight;
            weight = weight % 9 + 1;
        }

        const dv = sum % 11 < 2 ? 0 : 11 - sum % 11;
        return String(dv);
    }
}

class PolishPesel extends IdentityCard 
{
    constructor(cardId) 
    {
        super('PESEL', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Polish PESEL cardId is correct
        const regex = /^\d{11}$/;

        if (!regex.test(this.cardId)) 
        {
            return false;
        }

        const pesel = this.cardId;
        const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
        let sum = 0;
        
        for (let i = 0; i < pesel.length - 1; i++) 
        {
            sum += parseInt(pesel.charAt(i), 10) * weights[i];
        }

        const dv = 10 - sum % 10;
        return String(dv) === pesel.slice(-1);
    }
}

class PeruvianDni extends IdentityCard 
{
    constructor(cardId) 
    {
        super('DNI_PE', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Peruvian DNI cardId is correct
        const regex = /^\d{8}$/;
        return regex.test(this.cardId);
    }
}

class BolivianIdCard extends IdentityCard 
{
    constructor(cardId) 
    {
        super('C�dula de Identidad', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Bolivian ID card cardId is correct
        const regex = /^\d{7,8}$/;
        return regex.test(this.cardId);
    }
}

class EcuadorianIdCard extends IdentityCard 
{
    constructor(cardId) 
    {
        super('C�dula de Identidad', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Ecuadorian ID card cardId is correct
        const regex = /^\d{10}$/;
        return regex.test(this.cardId);
    }
}

class VenezuelanIdCard extends IdentityCard 
{
    constructor(cardId) 
    {
        super('C�dula de Identidad', cardId);
    }

    normalize(cardId) {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() {
        // We override the validate method to check that the Venezuelan ID card cardId is correct
        const regex = /^\d{7,8}$/;
        return regex.test(this.cardId);
    }

}

class UruguayanDnic extends IdentityCard 
{ 
    constructor(cardId) 
    {
        super('DNIC', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Uruguayan DNIC cardId is correct
        const regex = /^\d{8}$/;
        return regex.test(this.cardId);
    }
}

class ArgentineDni extends IdentityCard 
{
    constructor(cardId) 
    {
        super('DNI', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Argentine DNI cardId is correct
        const regex = /^\d{7,8}$/;
        return regex.test(this.cardId);
    }
}

class ColombianIdentityCard extends IdentityCard 
{
    constructor(cardId) 
    {
        super('DI Colombia', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Colombian identity document cardId is correct
        const regex = /^\d{8,10}$/;
        return regex.test(this.cardId);
    }
}

class ParaguayanIdCard extends IdentityCard 
{
    constructor(cardId) 
    {
        super('C�dula de Identidad Civil', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Paraguayan ID card cardId is correct
        const regex = /^\d{7,8}$/;
        return regex.test(this.cardId);
    }
}

class UsIdentityCard extends IdentityCard 
{
    constructor(type, cardId) 
    {
        super(type, cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the US identity document cardId is correct
        // This method can be used for any type of US identity document
        const regex = /^[A-Z0-9]{7,20}$/;
        return regex.test(this.cardId);
    }
}

class FrenchCni extends IdentityCard 
{
    constructor(cardId) 
    {
        super('CNI', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the French CNI cardId is correct
        const regex = /^\d{15}$/;
        return regex.test(this.cardId);
    }
}

class BelgianEid extends IdentityCard 
{
    constructor(cardId) 
    {
        super('eID', cardId);
    }

    normalize(cardId) 
    {
        // We override the normalize method to remove white spaces and convert letters to uppercase
        return cardId.trim().toUpperCase();
    }

    validate() 
    {
        // We override the validate method to check that the Belgian eID cardId is correct
        const regex = /^\d{12}$/;
        return regex.test(this.cardId);
    }
}

class DominicanRnc extends IdentityCard
{
    constructor(cardId) 
    {
        super('DoRNC',cardId);
    }

    normalize(cardId)
    {
        return cardId;
    }

    validate()
    {
        const regex = /^(\d{9,11})$/;
        return regex.test(this.cardId);
    }
}

class DominicanCedula extends IdentityCard
{
    constructor(cardId) 
    {
        super('DoCedula',cardId);
    }

    normalize(cardId)
    {
        return cardId;
    }

    validate()
    {
        const regex = /^(\d{9,11})$/;
        return regex.test(this.cardId);
    }
}

class ColombianNit extends IdentityCard
{
    constructor(cardId) 
    {
        super('CoNit',cardId);
    }

    normalize(cardId)
    {
        return cardId;
    }

    validate()
    {
        const regex = /^[a-zA-Z0-9\-]+$/;
		return regex.test(this.cardId);
    }
}

class ColombianCedula extends IdentityCard
{
    constructor(cardId) 
    {
        super('CoCedula',cardId);
    }

    normalize(cardId)
    {
        return cardId;
    }

    validate() 
    {
    	const regex = /^[0-9]+$/;
    	return regex.test(this.cardId);
	}
}

class ColombianTarjeta extends IdentityCard
{
    constructor(cardId) 
    {
        super('CoTarjeta',cardId);
    }

    normalize(cardId)
    {
        return cardId;
    }

    validate()
    {
        const regex = /^[a-zA-Z0-9\-]+$/;
		return regex.test(this.cardId);
    }
}

class ColombianCedulaExtranjeria extends IdentityCard
{
    constructor(cardId) 
    {
        super('CoCedulaExtranjeria',cardId);
    }

    normalize(cardId)
    {
        return cardId;
    }

    validate()
    {
        const regex = /^[a-zA-Z0-9\-]+$/;
		return regex.test(this.cardId);
    }
}

